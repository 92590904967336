import React, {useContext, useEffect, useState} from 'react';
import {navigate} from '@reach/router';
import {useLazyQuery} from '@apollo/client';

import {ME, HEXOSCOPE_USER} from '../graphql/queries';
import {UserContext} from '../contexts/user-context';

const useSetUser = (only?: 'user' | 'hexoscope_user') => {
  const {setUser, setHexoscopeUser} = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [refetch, {data: userData, loading: userDataLoading}] = useLazyQuery(ME, {
    fetchPolicy: 'no-cache',
    context: {clientName: 'v2'},
  });
  const [refetchUser, {data: hexoscopeUserData, loading: hexoscopeUserLoading}] = useLazyQuery(HEXOSCOPE_USER, {
    fetchPolicy: 'no-cache',
    context: {clientName: 'v2'},
  });

  useEffect(() => {
    if (only !== 'hexoscope_user' && userData && userData.User && userData.User.get) {
      setUser(userData.User.get.id ? {...userData.User.get} : {});
    }
  }, [userData]);

  useEffect(() => {
    if (only !== 'user' && hexoscopeUserData?.HexoscopeUser?.getHexoscopeUser) {
      setHexoscopeUser(
        hexoscopeUserData.HexoscopeUser.getHexoscopeUser?.hsUser?.id ? {...hexoscopeUserData.HexoscopeUser.getHexoscopeUser} : {},
      );
    }
  }, [hexoscopeUserData]);

  useEffect(() => {
    setLoading(hexoscopeUserLoading || userDataLoading);
  }, [hexoscopeUserLoading, userDataLoading]);

  const getUser = async (shouldNavigate?: boolean, setEmptyUser?: boolean) => {
    only !== 'hexoscope_user' && await refetch({
      variables: {
        toggle: new Date().toString(),
      }
    });
    only !== 'user' && await refetchUser({
      variables: {
        toggle: new Date().toString(),
      }
    });
    shouldNavigate && navigate('/');
    setEmptyUser && !userData?.User?.get?.id && setUser(userData?.User?.get?.id ? {...userData.User.get} : {});
  };
  return {getUser, loading: loading};
};

export default useSetUser;
